import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Hook to get common date filter labels for Filter components
 * @param {String} prefix Prefix to use for the translation keys
 * @param {Number} timeStep Time step for time filters
 * @returns {Object} Object containing all date filter label properties
 */
export const useDateFilterLabels = (prefix = 'common.date', timeStep = 5) => {
    const { t, i18n } = useTranslation();

    return useMemo(
        () => ({
            locale: i18n.language,
            timeStep: timeStep,
            labelAfter: t(`${prefix}.after`),
            labelBefore: t(`${prefix}.before`),
            labelBetween: t(`${prefix}.between`),
            labelSelect: t(`${prefix}.select`),
            labelDate: t(`${prefix}.date`),
            labelHour: t(`${prefix}.hour`),
            labelStartDate: t(`${prefix}.start_date`),
            labelStartHour: t(`${prefix}.start_hour`),
            labelEndDate: t(`${prefix}.end_date`),
            labelEndHour: t(`${prefix}.end_hour`),
            customLabelEndDate: t(`${prefix}.end_date`)
        }),
        [i18n.language, prefix, timeStep, t]
    );
};
