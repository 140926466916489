import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PendingExecutedCalls, WebhookCalls } from '../../models';
import { Button, Icon, WeezHeader, ToastContext } from '@weezevent/nacre';

import { usePermissions } from '../../models/user/permissions';
import { ExecutedCalls } from './components';

export const WebhookDetail = ({ id, pendingCallId, organizationId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const toast = React.useContext(ToastContext);
    const user = usePermissions();
    const [accordionsExpanded, setAccordionsExpanded] = React.useState(true);

    const [executedCalls, loadingExecutedCalls, syncExecutedCalls] = PendingExecutedCalls.useApiModel(
        {
            webhookId: id,
            pendingCallId,
            organizationId,
            cache: false,
            allow_cache: false,
            query: { limit: 50, offset: 0, ordering: '-created' }
        },
        [id, pendingCallId, organizationId]
    );

    const backLink = React.useMemo(() => {
        if (!organizationId) {
            return `/tools/webhooks/${id}`;
        }
        return `/organizations/O${organizationId}/tools/webhooks/${id}`;
    }, [organizationId]);

    // Handler for retrying the pending call
    const handleRetryCall = React.useCallback(() => {
        const pendingCall = new WebhookCalls({ id: pendingCallId });

        pendingCall
            .retry({ webhookId: id, organizationId })
            .catch(() => {
                toast.error(t('sauron.toasts.error'));
            })
            .finally(() => {
                syncExecutedCalls();
            });
    }, [pendingCallId, id, organizationId, toast, t, syncExecutedCalls]);

    return (
        <>
            <WeezHeader
                rightComponent={[
                    <Button
                        key="toggle-accordions"
                        secondary
                        icon={<Icon name={accordionsExpanded ? 'folder-closed' : 'folder-open'} />}
                        label={t(accordionsExpanded ? 'sauron.webhooks.detail.collapse_all' : 'sauron.webhooks.detail.expand_all')}
                        onClick={() => setAccordionsExpanded(prev => !prev)}
                        disabled={loadingExecutedCalls || !executedCalls?.length}
                    />,
                    user.canEditWebhooks() && (
                        <Button
                            key="retry-notification"
                            primary
                            label={t('sauron.webhook.logs.actions.trigger')}
                            onClick={handleRetryCall}
                            disabled={loadingExecutedCalls || !executedCalls?.length}
                        />
                    )
                ]}
                title={pendingCallId || ' '}
                backLink={{
                    onClick: () => {
                        history.push(backLink);
                    }
                }}
            />
            <ExecutedCalls executedCalls={executedCalls} loadingExecutedCalls={loadingExecutedCalls} accordionsExpanded={accordionsExpanded} />
        </>
    );
};
