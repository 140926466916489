import React from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Status } from '@weezevent/nacre';

import css from './webhook.module.css';
import { LoadingCard, SauronEmptyCard } from '../../../components';
import { formatDateWithTime } from '../../../utils/utils';
import { CALL_STATUS_COLORS } from '../webhookConstants';

export const ExecutedCalls = ({ executedCalls, loadingExecutedCalls, accordionsExpanded = true }) => {
    const { t } = useTranslation();

    if (!loadingExecutedCalls && !executedCalls?.length) {
        return <SauronEmptyCard title={t('sauron.webhook.logs.empty-card-title')} subTitle={t('sauron.webhook.logs.empty-card-subtitle')} />;
    }

    return (
        <LoadingCard loading={loadingExecutedCalls}>
            <div className={css['webhook-executed-calls-container']}>
                {executedCalls?.map(call => (
                    <ExecutedCallItem key={call.id} call={call} defaultOpen={accordionsExpanded} />
                ))}
            </div>
        </LoadingCard>
    );
};

const ExecutedCallItem = ({ call, defaultOpen }) => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = React.useState(defaultOpen);

    const statusInfo = React.useMemo(() => {
        // Default status object for pending state
        const pendingStatus = {
            status: t('sauron.webhook.notifications.status.pending'),
            color: CALL_STATUS_COLORS.pending
        };

        // Return pending if no response or can't extract status code
        if (!call.response) return pendingStatus;

        // Extract HTTP status code from response
        // Example: From "HTTP/1.1 200 OK" it will extract "200"
        const statusMatch = call.response.match(/HTTP\/\d+\.\d+\s+(\d{3})/);

        // If no HTTP status format was found in the response, consider it pending
        if (!statusMatch) return pendingStatus;

        const code = parseInt(statusMatch[1], 10);

        // Success: 200-299
        if (code >= 200 && code < 300) {
            return {
                status: t('sauron.webhook.notifications.status.success'),
                color: CALL_STATUS_COLORS.success
            };
        }

        return {
            status: t('sauron.webhook.notifications.status.failed'),
            color: CALL_STATUS_COLORS.failed
        };
    }, [call.response, t]);

    const jsonPayload = React.useMemo(() => JSON.stringify(call.payload, null, 4), [call]);

    React.useEffect(() => {
        setIsOpen(defaultOpen);
    }, [defaultOpen]);

    return (
        <Accordion
            open={isOpen}
            onChangeOpen={setIsOpen}
            title={
                <div className={css['webhook-executed-call-header']}>
                    <span>{`${formatDateWithTime(call.created)} - ${call.result}`}</span>
                    <Status color={statusInfo.color} text={statusInfo.status} responsive />
                </div>
            }
        >
            <h4 className={css['webhook-executed-call-section-title']}>{t('sauron.webhooks.detail.header_title')}</h4>
            <pre className={css['webhook-call-payload']}>{call.response}</pre>

            <h4 className={css['webhook-executed-call-section-title']}>{t('sauron.webhooks.detail.payload_title')}</h4>
            <pre className={css['webhook-call-payload']}>{jsonPayload}</pre>
        </Accordion>
    );
};
