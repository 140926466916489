import React from 'react';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Dropdown, DropdownItem, DropdownSort, FrameCard, Icon, Table, TableCellDropdown, ToastContext, FilterSetController, Filter, Status } from '@weezevent/nacre';

import { useDateFormat } from '../../../hooks';
import { usePermissions } from '../../../models/user/permissions';

import { ActionModel, Webhook, WebhookCalls } from '../../../models';

import { TotalCount, InfoTextTooltip, LoadingCard, PaginationDropDown, Paginator, SauronEmptyCard } from '../../../components';
import WebhookTriggerModal from './WebHookTriggerModal';

import { formatDateWithTime, handleSearchQuery } from '../../../utils/utils';

import css from './webhook.module.css';
import { CALL_STATUS_COLORS, webhookStatusFilterOptions } from '../webhookConstants';
import { useDateFilterLabels } from '../../../hooks/useDateFilterLabels';

const spec = {
    fields: ['status', 'created_date', 'last_execution_date'],
    date: ['created_date', 'last_execution_date']
};

export const PendingCalls = ({ webhook, organizationId, setOpenModal, syncWebhook }) => {
    const { t } = useTranslation();
    const user = usePermissions();
    const toast = React.useContext(ToastContext);
    const [queryParams, setQueryParams] = React.useState({ retried: false, limit: 50, offset: 0, disable_count: true, ordering: '-created' });
    const [currentPage, setCurrentPage] = React.useState(0);
    const [disableRetry, setDisableRetry] = React.useState(false);
    const [openTriggerModal, setOpenTriggerModal] = React.useState(false);
    const [paginationCount, setPaginationCount] = React.useState(0);
    const [filters, setFilters] = React.useState({});

    const dateFilterLabels = useDateFilterLabels();

    const [callsCount, loadingCallsCount] = WebhookCalls.useApiModel(
        {
            webhookId: webhook?.pk,
            organizationId,
            cache: false,
            allow_cache: false,
            query: { limit: 0, offset: 0 },
            launch: Boolean(webhook)
        },
        [webhook, organizationId, webhook?.pk]
    );

    const [pendingCalls, loadingPendingCalls, syncPendingCalls] = WebhookCalls.useApiModel(
        {
            webhookId: webhook?.pk,
            organizationId,
            cache: false,
            allow_cache: false,
            query: {
                ...queryParams,
                ...handleSearchQuery(spec, filters, true)
            },
            launch: Boolean(webhook)
        },
        [webhook, organizationId, queryParams, filters]
    );

    const handlePage = React.useCallback(
        value => {
            setCurrentPage(value);
            setQueryParams({ ...queryParams, offset: queryParams.limit * value });
        },
        [queryParams]
    );

    const isRetryDisabled = React.useMemo(() => {
        if (!webhook?.can_be_retried || loadingPendingCalls) {
            return true;
        }
        return disableRetry || !pendingCalls?.length;
    }, [webhook, pendingCalls, loadingPendingCalls, disableRetry]);

    const retryTooltip = React.useMemo(() => {
        if (!webhook || loadingPendingCalls || !pendingCalls?.length || webhook?.can_be_retried) {
            return '';
        }

        const { last_retry_failed_calls: last_retry } = webhook;
        const { properTime, properDate } = useDateFormat({ date: last_retry });
        const lastRetryDate = last_retry ? `${properDate} - ${properTime}` : null;
        const translationKey = `sauron.webhook.${last_retry ? 'trigger_tooltip_date' : 'trigger_tooltip'}`;

        return <Trans i18nKey={translationKey} values={{ date: lastRetryDate }} />;
    }, [webhook, loadingPendingCalls, pendingCalls]);

    const handleRetry = React.useCallback(() => {
        const action = 'retry_failed_calls';
        Webhook.executeBulkAction(
            {
                type: action,
                organizationId,
                id: webhook?.pk
            },
            Webhook
        ).then(response => {
            let values = { name: webhook?.name };
            setDisableRetry(true);
            toast.success(t(`sauron.webhook.actions.${action}.progress`, values));

            ActionModel.pollBulkAction(response)
                .then(rsp => {
                    if (rsp.status === 'success') {
                        values.count = rsp.summary.success;
                        toast.success(t(`sauron.webhook.actions.${action}.success`, { ...values }));
                    }
                })
                .catch(() => {
                    toast.error(t(`sauron.webhook.actions.${action}.error`, values));
                })
                .finally(() => {
                    setDisableRetry(false);
                    syncWebhook();
                });
        });
    }, [organizationId, webhook, syncWebhook]);

    return (
        <FrameCard
            id="webhook_logs"
            title={
                <div className={css['webhook-logs-trigger']}>
                    <div className={css['webhook-logs-block']}>
                        <span className={css['webhook-logs']}>{t('common.logs')}</span>
                        (
                        <TotalCount
                            pageQuery={pendingCalls}
                            countQuery={callsCount}
                            pageQueryLoading={loadingPendingCalls}
                            countQueryLoading={loadingCallsCount}
                            setCount={setPaginationCount}
                        />
                        )
                    </div>
                    {user.canEditWebhooks() && (
                        <InfoTextTooltip className={css['webhook-tooltip']} title={retryTooltip} tooltip={Boolean(retryTooltip)}>
                            <Button basic label={t('sauron.webhook.trigger')} icon={<Icon name="sort" />} onClick={() => setOpenTriggerModal(true)} disabled={isRetryDisabled} />
                        </InfoTextTooltip>
                    )}
                </div>
            }
            subtitle={t(`sauron.webhook.logs.subtitle`)}
        >
            <LoadingCard loading={loadingPendingCalls}>
                <WebhookTriggerModal open={openTriggerModal} onClose={() => setOpenTriggerModal(false)} onAccept={handleRetry} />
                <div className={css['webhook-filters-container']}>
                    <FilterSetController
                        labelFilters={t('sauron.webhooks.filters.label-filter')}
                        defaultValues={{}}
                        initialValues={filters}
                        showSearch={false}
                        onChange={value => {
                            setFilters(value);
                        }}
                        onReset={() => setFilters({})}
                    >
                        <Filter
                            index="0"
                            name="status"
                            labelName={t('sauron.webhook.notifications.status.label')}
                            labelCTA={t('sauron.webhooks.filters.label-cta')}
                            options={webhookStatusFilterOptions()}
                        />
                        <Filter
                            date
                            index="1"
                            name="created_date"
                            labelCTA={t('sauron.webhooks.filters.label-cta')}
                            labelName={t('sauron.webhook.notifications.filters.created_date.label')}
                            {...dateFilterLabels}
                        />
                        <Filter
                            date
                            index="2"
                            name="last_execution_date"
                            labelCTA={t('sauron.webhooks.filters.label-cta')}
                            labelName={t('sauron.webhook.notifications.filters.last_update.label')}
                            {...dateFilterLabels}
                        />
                    </FilterSetController>
                    <PaginationDropDown
                        label={'sauron.webhook.logs.page_size'}
                        displaySelect={false}
                        count={paginationCount}
                        limit={queryParams.limit}
                        pageSizes={[50, 100, 250]}
                        handlePage={handlePage}
                        onChange={value => {
                            setQueryParams({ ...queryParams, limit: value, offset: 0 });
                            setCurrentPage(0);
                        }}
                    />
                </div>
                <Table useNew>
                    <Table.THead>
                        <Table.Tr isHeader>
                            <th className={css['webhooks-table-medium']}>
                                <DropdownSort
                                    label={t('common.id')}
                                    name="id"
                                    active={['id', `-id`].includes(queryParams.ordering)}
                                    ordering={queryParams.ordering}
                                    size="mini"
                                    items={[
                                        <DropdownItem.Icons
                                            key={1}
                                            checked={queryParams.ordering === 'id'}
                                            item={t('common.ordering.ascending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: 'id' })}
                                        />,
                                        <DropdownItem.Icons
                                            key={2}
                                            checked={queryParams.ordering === '-id'}
                                            item={t('common.ordering.descending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: '-id' })}
                                        />
                                    ]}
                                />
                            </th>
                            <th className={css['webhooks-table-medium']}>
                                <DropdownSort
                                    label={t('common.created')}
                                    name="created"
                                    active={['created', `-created`].includes(queryParams.ordering)}
                                    ordering={queryParams.ordering}
                                    size="mini"
                                    items={[
                                        <DropdownItem.Icons
                                            key={1}
                                            checked={queryParams.ordering === 'created'}
                                            item={t('common.ordering.ascending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: 'created' })}
                                        />,
                                        <DropdownItem.Icons
                                            key={2}
                                            checked={queryParams.ordering === '-created'}
                                            item={t('common.ordering.descending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: '-created' })}
                                        />
                                    ]}
                                />
                            </th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.type`)}</th>
                            <th className={css['webhooks-table-medium']}>{t(`sauron.webhook.status`)}</th>
                            <th className={css['webhooks-table-medium']}>
                                <DropdownSort
                                    label={t('sauron.webhook.last_execution')}
                                    name="last_execution"
                                    active={['last_execution', `-last_execution`].includes(queryParams.ordering)}
                                    ordering={queryParams.ordering}
                                    size="mini"
                                    items={[
                                        <DropdownItem.Icons
                                            key={1}
                                            checked={queryParams.ordering === 'last_execution'}
                                            item={t('common.ordering.ascending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: 'last_execution' })}
                                        />,
                                        <DropdownItem.Icons
                                            key={2}
                                            checked={queryParams.ordering === '-last_execution'}
                                            item={t('common.ordering.descending')}
                                            onClick={() => setQueryParams({ ...queryParams, ordering: '-last_execution' })}
                                        />
                                    ]}
                                />
                            </th>
                            <th className={css['webhooks-table-medium']}>{t(`common.actions`)}</th>
                        </Table.Tr>
                    </Table.THead>
                    <Table.TBody>
                        {!loadingPendingCalls &&
                            pendingCalls?.length > 0 &&
                            pendingCalls?.map(call => (
                                <CallItem key={call.pk} organizationId={organizationId} call={call} webhook={webhook} setOpenModal={setOpenModal} sync={syncPendingCalls} />
                            ))}
                    </Table.TBody>
                </Table>
                {!loadingPendingCalls &&
                    (pendingCalls?.length > 0 ? (
                        <Paginator currentPage={currentPage} onPageChange={handlePage} pageSize={queryParams.limit} totalCount={paginationCount} />
                    ) : (
                        <SauronEmptyCard title={t('sauron.webhook.logs.empty-card-title')} subTitle={t('sauron.webhook.logs.empty-card-subtitle')} />
                    ))}
            </LoadingCard>
        </FrameCard>
    );
};

const CallItem = ({ webhook, call, setOpenModal, sync, organizationId }) => {
    const { t } = useTranslation();
    const user = usePermissions();
    const toast = React.useContext(ToastContext);
    const history = useHistory();

    const handleRowClick = React.useCallback(() => {
        const baseUrl = organizationId ? `/organizations/O${organizationId}/tools/webhooks/${webhook.pk}` : `/tools/webhooks/${webhook.pk}`;

        history.push(`${baseUrl}/${call.id}`);
    }, [history, webhook, call, organizationId]);

    const handleRetry = React.useCallback(() => {
        call.retry({ webhookId: webhook.pk })
            .catch(() => toast.error(t('sauron.toasts.error')))
            .finally(() => sync());
    }, [call, sync]);

    return (
        <Table.Tr onClick={handleRowClick} className={css['webhook-table-row-clickable']}>
            <Table.Td>{call.id}</Table.Td>
            <Table.Td>{formatDateWithTime(call.created)}</Table.Td>
            <Table.Td>{call.payload?.type ? t(`sauron.webhooks.filters.types.${call.payload.type}`) : '-'}</Table.Td>
            <Table.Td>
                <Status color={CALL_STATUS_COLORS[call.status]} text={t(`sauron.webhook.notifications.status.${call.status || 'created'}`)} responsive />
            </Table.Td>
            <Table.Td>{formatDateWithTime(call.last_execution)}</Table.Td>
            <TableCellDropdown>
                {user.canEditWebhooks() && (
                    <Dropdown.Item item={t('sauron.webhook.logs.actions.trigger')} onClick={handleRetry} disabled={call.status === 'success' || call.retried} />
                )}
                <Dropdown.Item item={t('sauron.webhook.logs.actions.payload')} onClick={() => setOpenModal({ open: true, call })} />
            </TableCellDropdown>
        </Table.Tr>
    );
};
