import moment from 'moment';
import { useDateFormat } from '../hooks';

/**
 * This function takes an array of field to perform case insensitive filters within lists
 * @param {string} values - Values can be passed as a string "name" or as an array ["name", "start_date"]
 * @returns {boolean} The search's result
 */
export const matchSearch = (values, search) => {
    if (!Array.isArray(values)) {
        values = [values];
    }
    // Escape special regex characters
    const safeSearch = search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const reg = new RegExp(safeSearch, 'gi');
    return values.some(value => reg.test(value));
};

/**
 * This function returns a list of unique ids to be used for checkboxes selections
 * @param {array} source - Selection source
 * @param {string} value - Value to be checked
 * @returns {array} Unique selection list
 */
export const uniqueSelection = (source, value) => {
    let selection = new Set(source);
    if (!selection.has(value)) {
        selection.add(value);
    } else {
        selection.delete(value);
    }
    return [...selection];
};

/**
 * This function performs a global selection on a given list
 * @param {array} source - A list of objects
 * @param {array} ids - A list of ids for comparison
 * @returns {array} Either a full selection, or none
 */
export const multipleSelection = (source, ids) => {
    const allSelected = ids.every(id => source.includes(id));
    return allSelected ? [] : ids;
};

/**
 * This function performs a unique or global selection on a given list, depending on parameters
 * @param {array} source - A list of objects
 * @param {array} ids - A list of ids for comparison
 * @param {string} value - Value to be checked
 * @returns {array} If value is passed, unique selection list. Else, either a full selection, or none.
 */
export const listSelection = (source, ids, value) => {
    if (!source) {
        throw new Error('[listSelection] source is mandatory');
    }
    if (!value) {
        return multipleSelection(source, ids);
    }
    return uniqueSelection(source, value);
};

export const resolve = (value, fallback) => {
    if (!value) {
        return fallback;
    }
    return value;
};

/**
 * This function allows to dymalically edit list using front toggles, it can either add items , or delete at specific index
 * @param {array} source - A list of objects
 * @param {object} additions - An element to be pushed to the source list
 * @param {number} value - Value to switch from push to splice (usually null/index)
 * @returns {array} Returns edited source list.
 */
export const pushOrSplice = (source, additions, value) => {
    if (!source) {
        throw new Error('[pushOrSplice] source is mandatory');
    }
    const addition = { ...additions };
    let new_source = [...source];

    if (!value && value !== 0) {
        return [...new_source, addition];
    } else {
        new_source.splice(value, 1);
        return new_source;
    }
};

export const getCount = (request, header) => request?.__meta?.headers.get(header);

export const requestCount = (loading, request) => {
    if (loading) {
        return '--';
    }
    return Number.parseInt(getCount(request, 'total-count')) || 0;
};

export const isEmpty = obj => Object.entries(obj).length === 0;

export const isEmail = value => {
    if (!value) {
        return false;
    }
    const rule = new RegExp(/^[\w-.+]+@([\w-]+\.)+[\w-]{2,5}$/);
    return rule.test(value) && value.split('@')[1] !== 'free.fr' && value.split('@')[1] !== 'laposte.net';
};

function processFilters(filters, callback) {
    Object.entries(filters).forEach(([key, value]) => {
        callback(key, value);
    });
}

function handleTruncate(spec, key, truncate) {
    if (truncate && !spec.fields?.includes(key)) {
        return true;
    }
    return false;
}

function handleSetValues(query, key, value) {
    if (value instanceof Set) {
        query[key] = Array.from(value).join(',');
        return true;
    }
    return false;
}

function handleDateFilter(spec, query, key, value) {
    if (spec.date?.includes(key) && value instanceof Object && !Array.isArray(value)) {
        Object.entries(value).forEach(([_key, _value]) => {
            if (_value) {
                query[`${key}_${_key}`] = moment(_value)
                    .set({ second: 0, millisecond: 0 })
                    .toISOString();
            }
        });
        return true;
    }
    return false;
}

export function handleSearchQuery(spec, filters, truncate = false) {
    let query = {};

    processFilters(filters, (key, value) => {
        switch (true) {
            case handleTruncate(spec, key, truncate):
                return;

            case handleSetValues(query, key, value):
                return;

            case handleDateFilter(spec, query, key, value):
                return;

            default:
                query[key] = value;
                break;
        }
    });

    return query;
}
export const capitalizeFirstLetter = string => {
    if (!string || string.length === 0) {
        return '';
    }

    return `${string.charAt(0).toUpperCase()}${string.slice(1).toLowerCase()}`;
};

export const validate = value => {
    return value || null;
};

export const checkIsValidJSON = value => {
    try {
        JSON.parse(value);
        return true;
    } catch {
        return false;
    }
};

/**
 * Format a date with time
 * @param {string} date - Date to format
 * @param {string} fallback - Fallback value
 * @returns {string} Formatted date
 */
export const formatDateWithTime = (date, fallback = '-') => {
    if (!date) return fallback;

    try {
        const { properTime, properDate } = useDateFormat({ date });
        return `${properDate} - ${properTime}`;
    } catch (error) {
        console.error('Error formatting date:', error);
        return fallback;
    }
};
